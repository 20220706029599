.clientgallery {
    margin: 0 10%;
    .gallery-img {
        width:100%;
        display:block;
        margin: 1.5% 0;
        img {
            width:100%;
        }
    }
}

.next-project {
    margin: 30px 0;
    padding: 20px;
    text-align: center;
    a {
        font-size: 25px;
        padding: 20px;
        border: 2px solid #F6EB00;
        border-radius: 40px;
        transition: all .25s linear;
        text-decoration:none;
        img {
            width: 50px;
        }
    }
    a:hover{
        background-color: #181E21;
        color: #F6EB00;
        img {
            animation: spaz 0.5s;
            animation-iteration-count: infinite;
            filter: brightness(0) invert(1);
        }
    }
}
