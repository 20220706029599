.t-landing{
    h1{
        font-size: clamp(70px, 9vw, 212px) !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        line-height: 1.1!important;
        & > span{
            margin-bottom: -5px;
        }
    }
    h1{
        line-height:1.05;
    }
    h2, h3, h4{
        line-height: 1!important;
    }
    p{
        font-size: 22px!important;
    }

    .font-body{
        font-family: $proxima-nova;
    }
    .font-heading{
        font-family: $big-city;
    }

    .fw-heavy{
        font-weight: 800;
    }
    .fw-black{
        font-weight: 900;
    }

    .btn{
        position: relative;
        &.btn-pink:hover{
            background-color: $yellow;
            border-color: $yellow;
            color: $dark-green!important;
        }
        &:hover{
            animation: shake .5s ease-in-out;
            &::after{
                content:"";
                top:-50px;
                bottom: -50px;
                left:0;
                right:0;
                position: absolute;
                z-index: 0;
            }
        }
        &.btn-pink, &.btn-outline-pink, &.btn-outline-purple ,&.btn-outline-blue{
            font-weight: 800;
            border-width: 5px;
            font-family: $big-city;
            padding-top: 15px;
        }
    }

    .snap{
        scroll-snap-align:start;
    }
    .intro-text-section{
        h1.gradient, h2.gradient{
            @include media-breakpoint-down(md){
                position: relative;
                z-index: 9;
                color: $white!important;
                background: none!important;
                background-clip:unset;
                animation: none;
                -webkit-text-fill-color:unset;
                aspect-ratio: 4 / 5;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                text-align: end;
            }
        }
    }


    canvas {
        width: 100% !important;
        height: 100% !important;
    }
    .visually-hidden-mobile{
        @include media-breakpoint-down(md){
            opacity: 0;
            position: absolute !important;
            pointer-events: none;
        }
    }
    .visually-hidden-desktop{
        @include media-breakpoint-up(md){
            opacity: 0;
            position: absolute !important;
            pointer-events: none;
        }
    }

    a.with-line {
        background:
        linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
        ),
        linear-gradient(
            to right,
            rgba(231,247,8,1),
            rgba(155,137,244,1),
            rgba(255,80,118,1)
        );
        background-size: 100% 3px, 0 3px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 400ms;
        &:hover {
            background-size: 0 3px, 100% 3px;
        }
    }
}