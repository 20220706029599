/* ==========================================================================
Case Studies Pages
========================================================================== */
.t-case-study {
    .header-section {
        padding-top: 33%;
        background-color: rgba(59, 77, 86, 0.09);
        position: relative;
    }
    .header-section img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 90%;
        max-width: 800px;
    }
    .leftpics, .rightpics, .gallery-img {
        display: inline-block;
        position: relative;
        margin: 10px 0;
        width: 50%;
    }
    .slide-img {
        background-position: center;
        background-size: cover;
    }
    .grid:after {
    content: '';
    display: block;
    clear: both;
    }
    /* ---- grid-item ---- */
    .grid-sizer,
    .grid-item {
    width: 48.5%;
    margin: 0.75%;
    }

    .grid-item {
    float: left;
    overflow: hidden;
    }
    .grid-item.video {
        padding-top: 52%;
    }

    .grid-item--width-2 {
        width: 98.5%;
    }
    .clientpics .external-link {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
    }
    .rightsplit .slide-img, .leftsplit .slide-img {
        padding-top: 54%;
        background-size: cover;
        background-position: center;
    }
    .leftimg .slide-img, .rightimg .slide-img {
        padding-top: 111%;
    }
    .bigpic {
        display: block;
    }
    .bigpic > div {
        padding-top: 90%;
    }
    .video-wrapper.slide-img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .clientpics {
        max-width: 1200px;
        margin: 50px auto 75px;
    }
    .client-gallery {
        max-width: 1200px;
        margin: auto;
        text-align: center;
    }
    .clienteditable {
    max-width: 800px;
    margin: auto;
    }
    .client-gallery > img {
        padding-top: 100%;
        max-height: 700px;
        margin-bottom: 50px;
    }
    .header {
    max-width: 1200px;
    margin: 50px auto;
    color: #FFFFFF;
    position: relative;
    }
    .caseheadeerthird {
    width: 400px;
    display: inline-block;
    vertical-align: top;
    padding: 0 25px;
    box-sizing: border-box;
    }
    .caseheadeerthird h3, .caseheadeerthird h2 {
    margin: 30px 0 15px 0px;
    line-height: 1;
    }
    .caseheadeerthird h3 {
    font-size: 30px;
    }
    .caseheadeerthird p {
    margin: 0;
    }
    .header:before {
    content: "";
    position: absolute;
    top: -50px;
    bottom: -50px;
    left: -1000%;
    right: -1000%;
    background-color: inherit;
    z-index: -1;
    }
    .next-case-study {
        margin: 30px 0;
        padding: 20px;
        text-align: center;
    }
    .next-case-study > a {
        font-size: 25px;
        padding: 20px;
        border: 2px solid #F6EB00;
        border-radius: 40px;
        transition: all .25s linear;
        text-decoration:none;
    }
    .next-case-study > a > img {
        width: 50px;
    }
    .next-case-study > a:hover > img {
        animation: spaz 0.5s;
        animation-iteration-count: infinite;
        filter: brightness(0) invert(1);
    }
    .next-case-study > a:hover {
        background-color: #181E21;
        color: #F6EB00;
    }
}