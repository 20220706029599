table.dropdown-menu{
    th{
        color:#777;
       
    }
    td{
        border-top: 1px solid #BBB;
        border-top: 1px solid #BBB;
        border-collapse: collapse;
    }
    td, th{
        white-space: nowrap;
        padding:1rem;
        text-align: center;
      
    }
    tr{
        td:first-child{
            text-align: left;
            padding-left: 2rem;
        }
    }
}
.green-text{
    color: green;
}
.red-text{
    color: red;
}
#feedback td,
#feedback th {
    padding:5px;
}