/* ==========================================================================
Splash
========================================================================== */
.banners-home {
    background-color: #181E21;
    max-height: 100vh;
    overflow: hidden;
    .banner-video-container {
        height:100vh;
        position:relative;
        overflow-y:hidden;
        background: #ffffff;
        color: #ffffff;
        .banner-video, canvas {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            width:100%;
            height:100%;
            object-fit: cover;
            transition:transform 3s linear;
            &.zoom {
                transform: scale(1.2);
            }
        }
        .video-logo {
            position: absolute;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
            z-index: 10;
            pointer-events: none;
            width: 75%;
        }
        .smoke-banner-scroll {
            position: absolute;
            top:90%;
            right:1%;
            padding: 8px;
            z-index: 10;
            border:none;
            color:white;
            background-color: rgba(255,255,255,0.25);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 32px;
            opacity: 0;
            transition: 1s ease opacity;
            &.show {
                opacity: 1;
            }
        }
    }
}
.banners-home > .banner-stage {
    height: 100vh;
    position:relative;
}
.banners-home .banner {
    position:absolute;
    top:0;
    left: 0;
    right:0;
    bottom:0;
    display:block;
    background-position:center;
    background-size:cover;
    z-index: 2;
}
.banner-img {
    position: relative;
    width: 150px;
    height: 85px;
    margin-left: 100px;
    margin-top: 12px;
}
/* left content */
.banners-home .banner-name {
    /* display: none; */
    color: white;
    position: absolute;
    left: 50px;
    bottom: 50%;
    font-size: 50px;
    font-weight: 100;
    line-height:1;
}

.banner-nav-item {
    display: inline-block;
    width: 100px;
    max-width: 15%;
    height: 3px;
    background-color: #666666;
    transition: background-color 0.5s ease;
    margin: 0px 6px;
}
iframe.banner-frame {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
}
.banner-nav-item.active {
    background-color: #F6EB00;
}
.banner-nav-item > span.banner-name,
.banner-nav-item > span.banner-title,
.banner-nav-item > span.banner-caption {
    display: none;
}
.banners-home .banner-navigation {
    position: absolute;
    left: 50px;
    right: 0;
    bottom: 150px;
}
.banner-navigation-body {
    display: flex;
    width: 50%;
}

.t-home {
	div#stickynav {
		background: transparent;
		position: absolute;
		top:0;
		left:0;
		right:0;
	}
}
.banners-home .banner-stage {
	display: flex;
	flex-direction: column;
	height:auto;
	.banner-left-content {
		position: static;
		order:1;
		.banner-name {
			position: static;
			text-align: center;
			display: block;
			padding:1em;
			font-size: 6vw;
		}
	}
	.banner-right-content {
		left:auto;
		width: 100%;
		position:static;
		aspect-ratio: 1;
	}
}
@media only screen and (max-width: 960px) {
    .t-home main {
        margin-top: 30px;
    }
     .banners-home .banner-stage {
        display: flex;
        flex-direction: column;
        height:auto;
        .banner-left-content {
            position: static;
            order:1;
            .banner-name {
                position: static;
                text-align: center;
                display: block;
                padding:1em;
                font-size: 6vw;
            }
        }
        .banner-right-content {
            left:auto;
            width: 100%;
            position:static;
            aspect-ratio: 1;
        }
    }
}
@media only screen and (max-width: 500px) {
    .banners-home .banner-title {
        font-size: 11vw;
    }
}
