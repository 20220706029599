.mainmenu span.subtitle {
    display: none;
}
.depth-0 .depth-1 > ul > li {
    margin: 0;
    width: 100%;
    padding: 2px 0;
}
.depth-0 .depth-1 > ul {
    display: block;
    font-size: 14px;
    position: relative;
}
li.depth-1.has-sub {
    & > a{
        font-weight: bold;
    }
}
li:hover > ul {
    display:block;
}
li.depth-1:hover > a,
li.depth-2:hover {
    background-color: #F6EB00;
    color: #000000;
    a{
        color: #000000;
    }
}

.header-menu-wrapper{
    font-size:14px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: right;
    line-height: 1.2;
    font-family: "proxima-nova", sans-serif;
    li{
 
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        position: relative;
    }

    .headermenu > ul > li,
    .mainmenu > ul > li {
        padding: 10px 0;
    }
    li.depth-1.has-sub ul {
        max-height : 1000px;
        font-weight: normal;
    }
    & > .headermenu{
        color: gray;
        text-transform: capitalize;
        font-size: 12px;
        margin: 5px;
        transition: all .5s;
        & > ul{
            list-style: none;
            text-transform: uppercase;
             
        }
    }
    & > .mainmenu{
        color: #fff;
        font-size: 18px;
        margin: 5px;
        transition: all .5s;
        & > ul{
            list-style: none;
            
            li.top{
                padding:0;
                padding-top: 5px;
                margin:0 15px;
            }
            li.top.has-sub{
                & > ul{
                    position: absolute;
                    max-height: 0;
                    padding: 0 25px;
                    overflow: hidden;
                    transition: all .25s linear;
                    width: 347px;
                    margin-left: -173px;
                    text-align: left;
                    transform: translate3d(0, 0, 0);
                    top: 100%;
                    left: 50%;
                    display: flex;
                    
                    }
                    li.depth-1.has-sub {
                        width: 130px;
                        padding:0;
                        margin:0 15px;
                        li{
                            display: inline-block;
                            vertical-align: top;
                            position: relative;
                            margin:0;
                            padding:2px;
                   
                            a{
                                padding-left: 15px;
                            }
                    }
                } 

                &:hover{
                    &:after{
                        content: "";
                        position: absolute;
                        border: 5px solid #f6eb00;
                        left: 50%;
                        margin-left: -2.5px;
                        top: 0px;
                        border-radius: 5px;
                    }
                    & > ul{
                       
                        background-color: #000;
                        padding: 25px;
                        height: auto;
                        max-height: 1000px;
              
                    }
                    &:before {
                        transform: rotate(180deg) translateY(50%);
                    }
                }
                &:before {
                    content: "";
                    position: absolute;
                    display: block;
                    left: 100%;
                    background-image: url('../assets/menu-arrow.svg');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 10px;
                    height: 15px;
                    top: 50%;
                    margin: 0 5px;
                    transform: translateY(-50%);
                    transition: transform .25s ease;
                }
                
            }
        }
    }
}

.home-menu.main-menu{
    position: absolute;
    left: 100px;
    top: 200px;
    font-size: 20px;
    flex-direction: column;
    display: flex;

    ul {
        line-height:0;
        display: flex;
        flex-direction: column;
    }
    li {
        margin: 0;
        padding: 0;
       
        line-height: 1.2;
        
    }
    


    .depth-0 .depth-1 > ul {
        display: block;
        font-size: 14px;
        position: relative;
    }
    li.depth-1.has-sub {
        & > a{
            font-weight: bold;
        }
        li a{
            font-weight: normal;
            width: 140px;
            @media screen and (min-width:1200px) {
                width: max-content; 
                margin-right: 20px;
            }
        }
    }

    .headermenu {
        color: #808080;
        // margin: 20px;
        transition: all 0.5s;
        padding: 0;
        li.top > a {
            padding-left:0;
            margin-bottom: 20px;
        }
    }
    
    .mainmenu {
        color: #FFFFFF;
        margin-top: 20px;
        transition: all 0.5s;
        margin-bottom: 70px!important;
        a {
            width: 140px;
            @media screen and (min-width:1200px) {
                width: max-content; 
            }
        };

        li.top {
            padding-right: 40px;
            & > a{
                padding:0;
                // padding-right: 15px;
                padding-top: 15px;
                padding-bottom:8px;
                border-bottom: solid 4px transparent;
            }
            &:hover{
                & > a{
                    border-bottom: solid 4px #F6EB00;
                }
                
            }
            &.has-sub{          
                & > ul{
                    position: absolute;
                    overflow: hidden;
                    transition: all .35s linear;
                    max-width: 0;
                    text-align: left;
                    transform: translate3d(0,0,0);
                    top: 0;
                    left: 50%;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    @media screen and (max-width:1450px) {
                        flex-direction: column;
                        .depth-1{
                            margin-bottom: 10px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                &:hover > ul {
                    position: absolute;
                    background-color: black;
                    padding: 25px;
                
                    max-width: 500px;
                    left:100%;
                }
            }
        }
    }
    .headermenu, .mainmenu{
        font-size: 22px;
        text-transform: none;
        font-family: bigcity-grotesque-pro, sans-serif;
        font-weight: 700;
        letter-spacing: 2px;
    }
}

// .t-home.t-contact .header-menu-wrapper{
//     display: none;
// }