
// Author's custom styles
// we need to import this first so that it would override bootstrap scss variables later


@import "variables";
@import "form";
@import "utilities";
@import "~bootstrap/scss/bootstrap.scss";
@import "~@splidejs/splide/dist/css/splide.min.css";

.t-landing{
    margin: 0;
    padding: 0;
    main{
        height: 100vh;
        max-height: 100vh;
        overflow-y: scroll;
        scroll-snap-type: y mandatory;
        scrollbar-width: none;
    }
    
    
    #reverseScroll{
        display: flex;
        flex-direction: column-reverse;
        z-index: 9;
        bottom:0;
        z-index: -1;
        pointer-events: none;
        @include media-breakpoint-down(md){
            width: 100%!important;
            top: 0;
            left: 0;
            section{
                align-items: flex-start!important;
                img{
                    pointer-events: all;
                }
            }
        }
    }
    
    
    #client-section {
        $delay-interval: 0.1s;
        $total_img: 20;
        @for $i from 1 through $total_img {
            .client-section > div:nth-child(#{$i}) > img{
                transition-delay: $i * $delay-interval;
            }
        }
    
        .client-section{
            img{
                transition: all .3s ease-in-out;
                opacity: 0;
                transform: scale(0)rotate(-270deg);
            }
        }
        &.appear{
            .client-section{
                img{
                    opacity: 1;
                    transform: scale(1)rotate(0deg);
                }
            }
        }
    }
    
    
    
    section{
        .icon{
            width: clamp(250px, 25vw, 800px);
            aspect-ratio: 1/1;
            position: relative;
        }
        &.has-animation{
            .icon{
                @include media-breakpoint-down(md){
                    padding-top: 25vh;
                    background-color: $white;
                }
            }
        }
    }
    .client-section{
        img{
            width: clamp(120px, 6.5vw, 540px);
            transition: transform .3s ease-in-out;
            &:hover{
                transform: scale(1.15);
            }
        }
    }
    
    #keo-triangle{
        display: flex;
        justify-content: center;
        align-items: center;
        &.turn-1{
            #triangle{
                transform: rotate(31deg)translateX(-8%) translateY(-7%);;
            }
            &.turn-2{
                #triangle{
                    transform: rotate(90deg)translateX(-15%) translateY(-10%);
                }
            }
        }
        #triangle{
            transition: transform .3s ease-in-out;
            @include media-breakpoint-down(md){
                display : none;
                
            }
        }
        #keo{
            position: absolute;
            z-index: 3;
            right: 15%;
            left: 40%;
            width: 50%;
            @include media-breakpoint-down(md){
                display: none;
            }
        }
        @include media-breakpoint-down(md){
            aspect-ratio: 4 / 5;
            width: 100%;
            height: auto;
            transition: transform .3s ease-in-out;
            transform: scale(1.75);
            &::after{
                content:'';
                display: block;
                position: absolute;
                top: -25%;
                left: -25%;
                bottom: -25%;
                right: -25%;
                clip-path: polygon(0 50%, 100% 100%, 100% 0);
                // background:linear-gradient(90deg, rgba(231,247,8,1) 0%, rgba(155,137,244,1) 50%, rgba(255,80,118,1) 100%);
                aspect-ratio: 4 / 5;
                background:$gradient;
                background-size: 1000% auto;
                animation: textShine 15s linear infinite alternate;
            }
            &.turn-1{
                transform: rotate(-32deg) translateX(-16%) scale(1.25);
                &.turn-2{
                    transform: rotate(-60deg)translateX(-30vh) translateY(-10%)scale(1.2) scale(1.5);;
                    
                }
            }
        }
    }
    
    #brand-section{
        background-color: $green;
        background-image: url("../assets/img/spots-bg.svg");
        background-size: cover;
        background-blend-mode: soft-light;
        
        .icon img {
            animation-play-state: paused;  
            -webkit-animation: flip-only 2s ease-in-out infinite alternate;
            animation: flip-only 2s ease-in-out infinite alternate;
        }
        &.appear{
            .icon img {
                animation-play-state:running;
              
            }
        }
        @include media-breakpoint-down(xl){
            .icon{
                width: 70%;
            }
            h2{
                max-width: 500px;
                margin: 0 auto;
            }
        }
        @include media-breakpoint-down(md){
            .icon{
                width: 90%;
            }
            h2{
                max-width: 400px;
                margin: 0 auto;
            }
        }
    }
    .gradient{
        background:$gradient;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        background-size: 1000% auto;
        // background-position: left;
        animation: textShine 15s linear infinite alternate;
    }
    
    @keyframes textShine {
        0% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 12.5% 50%;
        }
    }
    
    @keyframes spinning {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(540deg);
        }
    }
    
    @keyframes shake {
        0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(10deg);
        }
        50% {
            transform: rotate(-10deg);
        }
        75% {
            transform: rotate(10deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    
    @keyframes rollIn {
        0% {
          opacity: 0;
          -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
          -ms-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
          transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)
        }
        100% {
          opacity: 1;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none
        }
    }
    
    
    @keyframes flip-only {
        0% {
            transform: rotate(0deg) rotateY(0deg);
        }
        33% {
            transform: rotate(0deg) rotateY(0deg);
        }
        66% {
            transform: rotate(-120deg) rotateY(-180deg)translate(-50px,-50px);
        }
        100% {
            transform: rotate(-150deg);
        }
    }
    #mobile-logo{
        display: none;
        top: 40px;
        left: 40px;
        width: 30vw;
        z-index: 99;
        transition: filter .3s ease-in-out;
        @include media-breakpoint-down(md){
            display : block;
        }
        &.appear{
            filter:brightness(0) invert(1);
        }
    }
    #keo-triangle-corner{
        width: clamp(80px, 10vw, 180px);
        height: auto;
        right: 0;
        bottom:0;
        transition: right .3s ease-in-out;
        &.hide{
            right: -200px;
        }
    }
    
    .intro-text-section{
        z-index: 9;
        @include media-breakpoint-down(md){
            .turn-1-flag h1{
                font-size: clamp(70px, 22vw, 212px) !important;
            }
            .turn-2-flag h2{
                display: flex;
                text-align: center;
                font-size: clamp(70px, 22vw, 212px) !important;
                margin-bottom: 0;
                justify-content: center;
            }
            .turn-3-flag h2{
                display: flex;
                text-align: start;
                font-size: clamp(70px, 22vw, 212px) !important;
                margin-bottom: 0;
                justify-content: center;
                transform: translateX(-10%);
            }
            section .icon{
                height: auto;
                width: 100%;
            }
            .play-if-appeared{
                h5{
                    text-align: right;
                    font-size: 3.5vw;
                }
                h2{
                    text-align: right;
                    font-size: 18vw;
                }
            }
        }
    }
    
    
    .scroll-icon {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 60px;
        left: 100%;
        width: 100px;
        right: 0;
        z-index: 999999;
        height: 50px;
        transform: translateX(-50%);
        @media screen and (min-width:2300px){
            transform: translateX(-10%);
        }
        @media screen and (max-height:1120px){
            transform: translateX(-10%);
        }
        
    }
    
    .mouse {
        width: 45px;
        height: 75px;
        border: 3px solid #9AAFA8;
        border-radius: 60px;
        position: relative;
        &::before {
            content: "";
            width: 5px;
            height: 22px;
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #9AAFA8;
            border-radius: 5px;
            opacity: 1;
            animation: wheel 2s infinite;
            -webkit-animation: wheel 2s infinite;
        }
    }
    
    
    @keyframes wheel {
        to {
            opacity: 0;
            top: 60px;
        }
    }
    
    @-webkit-keyframes wheel {
        to {
            opacity: 0;
            top: 60px;
        }
    }
    #footer nav > *{
        position: relative;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:0;
        font-size: 16px !important;
        background-color: transparent;
        &::after{
            content:"";
            height: 15px;
            width: 1px;
            position: absolute;
            left: 100%;
            background-color: $dark-green;
        }
        &:last-child{
            &::after{
                display: none;
            }
        }
        @include media-breakpoint-down(lg){
            padding: 0;
            &::after{
                display: none;
            }
        }
    }
    #footer nav > a{
        white-space: nowrap;
    }
    
    .splide__slide{
        display: flex;
        img{
            margin: auto;
        }
    }
    
    .btn.sending{
        pointer-events: none;
        position: relative;
        background: transparent;
        &::after{
            content:url('../assets/img/triangle.svg');
            position: absolute;
            animation: spinning 3s ease-in-out infinite alternate;
            left:0;
            top:0;
            display: block;
            width: 50px;
            height: 50px;
            transform-origin: 71% 50%;
        }
    }
    form{
        margin:0;
        max-width: none;
        display: block;
        margin:0;
        .btn{
            width: fit-content;
        }
        a{
            background: unset;
        }
    }
 
}
