
@import "variables";
@import "~bootstrap/scss/bootstrap.scss";

.t-landing{
    .btn{
        &.btn-pink:hover{
            background-color: $yellow;
            border-color: $yellow;
            color: $dark-green!important;
        }
        &:hover{
            animation: shake .5s ease-in-out;
        }
    }
    .btn-gradient{
        background:$gradient;
        background-size: 1000% auto;
        animation: textShine 15s linear infinite alternate;
        font-family: $big-city!important;
        font-size: 26px!important;
        font-weight: 800!important;
        color:$white!important;
        border-color: transparent!important;
        &:hover{
            background: $pink!important;
            border-color: $pink!important;
            color: $dark-green!important;
        }
    }
    form{
        .form-control, .form-control:hover{
            border-color: $dark-green;
            border-radius: 0;
            box-shadow: none !important;
            background-color: transparent;
            height: 42px;
            border-bottom:1px solid $dark-green ;
            
        }
        textarea.form-control{
            height: auto!important;
            min-height: unset;
        }

        .recaptcha-notice{
            font-size: 12px;
            font-weight: 300;
            float: left;
            margin-top: 1rem;
            @include media-breakpoint-down(md){
                text-align: center;
                float: none;
            }
        }
    }

    .js-float-wrap{
        position:relative;
        margin-bottom: 3rem;
        @include media-breakpoint-down(md){
            margin-bottom: 1.5rem;
        }
        label{
            position: absolute;
            // top:4px;
            color: inherit;
            max-width: 100%;
            box-sizing: border-box;
            transition: all 1s ease;
            z-index: 1;
            font-size: 21px;
            font-weight: 500;
            font-family: $font-secondary;
            bottom:0;
        }
        &.focused, &.populated{
            label{
                bottom: 40px;
                line-height: 1;
                font-size: 0.5em;
            }
        }
    }
}