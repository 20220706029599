.acknowledgement {
    color:#fff;
    background-color: #000;
    background-image: url('../assets/acknowledgement.jpg');
    background-position: center;
    background-size: cover;
    font-family: bigcity-grotesque-pro, sans-serif;
    font-weight:bold;
    text-align:center;
    padding: 3vh 20px;
    width:1600px;
    left:50%;
    margin-left:-820px;
    margin-top:-4vh;
    position:absolute;
    z-index:100;
}
.subscribe {
    padding-bottom:3vh;
}
.contact-panel-section, .acknowledgement-top-pad {
    padding-top:18vh;
}
@media only screen and (max-width: 1640px) {
    .acknowledgement {
        width:auto;
        position:static;
        left:auto;
        margin:0;
    }
    .subscribe {
        padding-bottom:0;
    }
    .contact-panel-section {
        padding-top:50px;
    }
    .acknowledgement-top-pad {
        padding-top:0;
    }
}