// For complete list of CSS variables (bootstrap 5.3)
// https://getbootstrap.com/docs/5.3/customize/css-variables/

// For complete list of SCSS variables 
// @import "../../node_modules/bootstrap/scss/_variables.scss";

// Theme colors

$enable-negative-margins: true; 


$purple:#9b89f4;
$blue:#428EFC;
$pink:#ff5076;
$black: #2E2E2E;
$white: #FFF7EB;
$yellow: #e7f708;
$green: #023826;
$dark-green: #002115;
$gradient: linear-gradient(80deg, rgba(231,247,8,1) 0%, rgba(155,137,244,1) 12.5%, rgba(255,80,118,1) 25%, rgba(231,247,8,1) 37.5%,rgba(155,137,244,1) 50%,rgba(255,80,118,1) 62.5%, rgba(231,247,8,1) 75%,rgba(155,137,244,1) 87.5%, rgba(255,80,118,1) 100%);

$theme-colors: (
  "pink": $pink,
  "blue": $blue,
  "purple": $purple,
  "dark-green": $dark-green,
  "white": $white,
  "yellow": $yellow
);

$proxima-nova: proxima-nova, sans-serif;
$big-city: bigcity-grotesque-pro, sans-serif; 
// Fonts
$font-primary: $big-city;
$font-secondary: $proxima-nova;

$font-family-base: $proxima-nova;
$font-size-base: 1rem;
$font-size-root: 24px;

$headings-font-family: $big-city;
$headings-color: $dark-green;
$body-color: $dark-green;

// Links
$link-color: $dark-green;
$link-decoration: none;


// For converting px to rem
// https://nekocalc.com/px-to-rem-converter

// Font size
$h1-font-size: 8.833rem;  //212px
$h2-font-size: 4.667rem;  //112px
$h3-font-size: 2rem;      //48px
$h4-font-size: 1.083rem;  //26px
$h5-font-size: 0.875rem;  //21px
$h6-font-size: 0.667;     //16px

$offcanvas-padding-y: 0;
$offcanvas-padding-x: 0;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 9,
);

$btn-font-size:0.944rem;
$btn-border-radius: 35px;

$btn-padding-x: 1.5rem;
$btn-padding-y: 0.5rem;

$input-border-radius:0;
$input-border-color: transparent;
$input-border-width: 0;
