.sub-templates {
    .subpage-wrap {
        position:relative;
    }
    .subpage-bg {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        img {
            width:100%;
            height:100% !important;
            object-fit:cover;
        }
    }
    .with-content .subpage-bg {
        opacity:0.1;
    }
    .subpage-wrap:not(.with-content).with-bg {
        min-height:40vw;
    }
    .subtemplate-rhs_cta, .subtemplate-lhs_cta {
        .subpage-content {
            display: flex;
            .subpage-contentwrap {
                flex-grow: 1;
                order:1;
                margin:1%;                
            }
            .subpage-extra {
                width: 35%;
                flex-grow: 0;
                flex-shrink: 0;
                order:2
            }
        }
    }
    .subtemplate-rhs_cta .subpage-content .subpage-extra {
        order:0;
    }
}